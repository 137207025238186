// We need this for the brand banner in the devise layout
body.l-devise {
  padding-top: 0 !important;
  .ma__brand-banner {
    margin-bottom: 40px;
  }
}

.ma__brand-banner {
  text-align: left;
  padding-left: 1rem;
}
