// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$primary:         #14558F;
$primary-hover:   #112470;
$primary-disable: #7198CC;
$primary-light:   #4377A5;
$secondary:       #535353;
$success:         #33754B;
$info:            $primary;
$warning:         #F6C51B;
$danger:          #CD0D0D;
$light:           #F8F5F0;
$dark:            #343A40;
$alert-info:      #D0DDE9;
$alert-success:   #D7E7DD;
$alert-warning:   #FDF3D1;
$alert-danger:    #E1CED2;
$bg-light:        #F2F2F2;

// Body
//
// Settings for the `<body>` element.

$body-color: #333;

// Links
//
// Style anchor elements.

$link-color:                              $primary;
$link-decoration:                         none;
$link-hover-color:                        $primary-hover;
$link-hover-decoration:                   underline;

// Breadcrumbs

$breadcrumb-active-color: #333;

// Fonts

$font-family-sans-serif: 'Noto Sans', sans-serif;
$font-size-base:              1rem;
$headings-font-weight:        400;
