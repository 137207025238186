// top row

.navbar-brand {
  img {
    max-height: 2.5rem;
  }
}

.navbar-brand__home-link {
  color: white;
  font-family: 'Oswald', sans-serif;
  font-size: 1.5rem;
  margin: 1px;
  &:hover {
    color: white;
  }
  &:focus {
    outline: none;
    .navbar-brand__home-link__text {
      outline: 2px solid;
    }
  }
}

.navbar__h-nav__navlink {
  padding: 0 calc($navbar-padding-x / 2);
  &:not(:last-child) {
    border-right: 1px solid $white;
  }
  .active {
    color: $white;
    background-color: $primary-light;
    border-radius: 50px
  }
  & > a {
    padding: 0 calc($navbar-padding-x / 2);
  }
}

.navbar__dropdown {
  top: 1.8rem;
  text-transform: capitalize;
  min-width: 13rem;
}

.navbar__dropdown__list {
  list-style: none;
  padding: 0;
}

.navbar__dropdown__navlink {
  border-radius: 0;
  padding: 0 1rem;
  text-indent: -0.5rem;
  &.active {
    border-radius: 0;
    background-color: $primary;
    color: $white;
    &:focus {
      outline-width: 3px;
      outline-style: double;
      outline-color: $black;
    }
  }
  &:hover {
    background-color: $primary-light;
    color: $white;
  }
}

.skiplink-to-main {
  display: block;
  border-bottom: 1px solid;
  min-width: 100%;
  margin: 0;
  text-align: center;
}

// vertical navbar

.navbar__v-nav {
  &.collapse:not(.show) {
    display: none;
  }
}

.navbar__v-nav__section-title {
  font-weight: bold;
  font-size: 1.1em;
}

.navbar__v-nav__links {
  .nav-item {
    list-style: none;
    margin-left: 0;
  }
}

.navbar__v-nav__navlink {
  color: $white;
  font-weight: normal;
  padding: 0 0.5rem;
  text-indent: -0.5rem;
  &:hover, &:focus {
    text-decoration: underline;
    color: $white;
    background-color: $primary-light;
  }
}
