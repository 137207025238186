body {
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
}

main:focus {
  outline: 0;
}

main a:not([class]) {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

a:focus,
.navbar-brand__home-link:focus .navbar-brand__home-link__text {
  outline: 2px solid;
}

label {
  font-weight: bold;
}

.bg-dark {
  background-color: $primary !important;
}

.titlebar {
  padding: 0.5rem 0 1rem;
}

.breadcrumbs {
  height: 1rem;
  ol {
    background-color: transparent;
    margin: 0;
    padding: 0;
    font-size: 0.75rem;
  }
}

.alert-success {
  background-color: $alert-success;
}
.alert-info {
  background-color: $alert-info;
}
.alert-warning {
  background-color: $alert-warning;
}
.alert-danger {
  background-color: $alert-danger;
}

.bg-light {
  background-color: $bg-light !important;
}

.btn {
  border-radius: 0;
	box-shadow: 0 .25rem .5rem rgba(1,1,1,.25);
	letter-spacing: .1em;
	transition: all .4s ease;
  margin: 0.25em;
}

.btn-outline-primary {
  background-color: $white;
}

h1 {
  font-size: 2.8rem;
  font-weight: bold;
  margin: 0;
}

nav.col-2 {
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
  }
  padding-left: 0;
  .nav-item {
    padding: 2px;
  }
  a {
    color: $body-color;
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: 2px solid;
    }
  }
}

nav .sidebar-sticky {
  .nav-link {
    border-radius: 0 50px 50px 0;
  }
  .nav-link.active {
    background-color: $bg-light;
    font-weight: bold;
  }
}

.nav-tabs {
  margin-bottom: 1rem;
}

form .form-row {
  max-width: 100%;
}

.form-control:focus {
  box-shadow: none;
  outline: 2px solid;
}

caption {
  caption-side: top;
  @include sr-only;
}

.diff li.ins {
  color: #008400 !important;
}

legend.col-form-label {
  @extend .h3;
}

.report-filter .check_boxes legend.col-form-label {
  font-size: 1rem;
  font-weight: bold;
}

.font-variant-small-caps {
  font-variant: all-small-caps;
}

.flex-basis-10 {
  flex-basis: 10rem;
}

.btn-link {
  box-shadow: none;
  letter-spacing: normal;
  text-transform: none;
}

.dashboard-widget {
  .table {
    border-bottom:0px !important;
  }
  .table th, .table td {
    border: 1px !important;
  }
  .fixed-table-container {
    border:0px !important;
  }
}

.double-border-bottom {
  border-bottom: 4px double #dee2e6;
}
