// We need this for the MA footer
.ma__footer-slim {
  font-size: inherit !important;
}

.ma__footer-slim__contact__item {
  font-style: italic;
}

.ma__site-logo a {
  display: block;
  padding: 2px;
}

.ma__button--quaternary {
  margin: 2px;
}
